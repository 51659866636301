import axios from "axios";
import io from "socket.io-client";

class DegpegClient {
  constructor(url, id) {
    this.baseUrl = url;
    this.contentPublisherId = id;
  }
  // getAllSessionsForcontentProvider() {
  //   const response = axios({
  //     method: "GET",
  //     url: `${this.baseUrl}/content-providers/${this.contentProviderId}/live-sessions`,
  //     headers: { "Content-Type": "application/json" }
  //   }).then((response) => response);

  //   return response;
  // }
  getAllContentProviderIds() {
    const response = axios({
      method: "GET",
      url: `${this.baseUrl}/content-publishers/${this.contentPublisherId}`,
      headers: { "Content-Type": "application/json" }
    }).then((response) => response.data.contentProviders);

    return response;
  }
  getAllLiveSessionsProductCategory() {
    const response = axios({
      method: "GET",
      url: `${this.baseUrl}/live-session-categories/`,
      headers: { "Content-Type": "application/json" }
    }).then((response) => response);
    return response;
  }
  getAllSessionData() {
    const response = axios({
      method: "GET",
      url: `${this.baseUrl}/session-data`,
      headers: { "Content-Type": "application/json" }
    }).then((response) => response);

    return response;
  }
  getChannelIds(channelId) {
    const response = axios({
      method: "GET",
      url: `${this.baseUrl}/channels/${channelId}`,
      headers: { "Content-Type": "application/json" }
    }).then((response) => response);
    return response;
  }
  // getAllSessions() {
  //   const response = axios({
  //     method: "GET",
  //     url: `${this.baseUrl}/content-providers/${this.contentProviderId}/live-sessions?filter={%22include%22:[{%22relation%22:%22liveSessionCategory%22}]}`,
  //     headers: { "Content-Type": "application/json" }
  //   }).then((response) => response);

  //   return response;
  // }
  async getAllSessionsOfContentProvider(id) {
    const response = await axios({
      method: "GET",
      url: `${this.baseUrl}/content-providers/${id}/live-sessions?filter={"include":[{"relation":"liveSessionCategory"}],"where":{"status":{"neq":"deleted"}}}`,
      headers: { "Content-Type": "application/json" }
    }).then((response) => response.data);
    return response;
  }
  getAllContentProviders(id) {
    const response = axios({
      method: "GET",
      url: `${this.baseUrl}/content-providers/${id}`,
      headers: { "Content-Type": "application/json" }
    }).then((response) => response);
    return response;
  }
  getContentProviderUserDetails(id) {
    const response = axios({
      method: "GET",
      url: `${this.baseUrl}/users?filter={"where":{"contentProviderId":"${id}"}}`,
      headers: { "Content-Type": "application/json" }
    }).then((response) => response);
    return response;
  }

  getSesseionChatMessages(id) {
    const response = axios({
      method: "GET",
      url: `${this.baseUrl}/chat-messages?filter={"where":{"liveSessionId":"${id}"}}`,
      headers: { "Content-Type": "application/json" }
    }).then((response) => response);

    return response;
  }
  postSessionChatMessages(msg) {
    const response = axios({
      method: "POST",
      url: `${this.baseUrl}/chat-messages`,
      headers: { "Content-Type": "application/json" },
      data: JSON.stringify(msg)
    }).then((response) => response);

    return response;
  }
  //All users
  getAllUserLoginDetails() {
    const response = axios({
      method: "GET",
      url: `${this.baseUrl}/users`,
      headers: { "Content-Type": "application/json" }
    }).then((response) => response);
    return response;
  }
  getUserLoginDetails(id) {
    const response = axios({
      method: "GET",
      url: `${this.baseUrl}/users/${id}`,
      headers: { "Content-Type": "application/json" }
    }).then((response) => response);
    return response;
  }

  getAllProdcts(id, contentProviderId) {
    const response = axios({
      method: "GET",
      url:
        `${this.baseUrl}/content-providers/${contentProviderId}/live-sessions?filter={"where":{"id":"` +
        id +
        `","status":{"neq":"deleted"}}}`,
      headers: { "Content-Type": "application/json" }
    }).then((response) => response);
    return response;
  }

  getProdcts(productId) {
    const response = axios({
      method: "GET",
      url:
        `${this.baseUrl}/products?filter={"where":{"id":"` + productId + `"}}`,
      headers: { "Content-Type": "application/json" }
    }).then((response) => response);
    return response;
  }
  getCtas(ctaId) {
    const response = axios({
      method: "GET",
      url: `${this.baseUrl}/user-ctas?filter={"where":{"id":"` + ctaId + `"}}`,
      headers: { "Content-Type": "application/json" }
    }).then((response) => response);
    return response;
  }
  postViews(data) {
    const response = axios({
      method: "POST",
      url: `${this.baseUrl}/views`,
      headers: { "Content-Type": "application/json" },
      data: data
    }).then((response) => response);
    return response;
  }
  postLikes(data) {
    const response = axios({
      method: "POST",
      url: `${this.baseUrl}/likes`,
      headers: { "Content-Type": "application/json" },
      data: data
    }).then((response) => response);
    return response;
  }
  postShares(data) {
    const response = axios({
      method: "POST",
      url: `${this.baseUrl}/shares`,
      headers: { "Content-Type": "application/json" },
      data: data
    }).then((response) => response);
    return response;
  }
  postPurchases(data) {
    const response = axios({
      method: "POST",
      url: `${this.baseUrl}/purchases`,
      headers: { "Content-Type": "application/json" },
      data: data
    }).then((response) => response);
    return response;
  }
  getLikesCount(id) {
    const response = axios({
      method: "GET",
      url:
        `${this.baseUrl}/likes/count?filter={"where":{"liveSessionId":"` +
        id +
        `"}}`,
      headers: { "Content-Type": "application/json" }
    }).then((response) => response);
    return response;
  }
  getSharesCount(id) {
    const response = axios({
      method: "GET",
      url:
        `${this.baseUrl}/shares/count?filter={"where":{"liveSessionId":"` +
        id +
        `"}}`,
      headers: { "Content-Type": "application/json" }
    }).then((response) => response);
    return response;
  }
  getViewsCount(id) {
    const response = axios({
      method: "GET",
      url:
        `${this.baseUrl}/views/count?filter={"where":{"liveSessionId":"` +
        id +
        `"}}`,
      headers: { "Content-Type": "application/json" }
    }).then((response) => response);
    return response;
  }
  getPurchaseCount(id) {
    const response = axios({
      method: "GET",
      url:
        `${this.baseUrl}/purchases/count?filter={"where":{"liveSessionId":"` +
        id +
        `"}}`,
      headers: { "Content-Type": "application/json" }
    }).then((response) => response);
    return response;
  }

  socketConnection(id) {
    // const response = io.connect(
    // Local Server
    // `https://dev.interactionserver.degpeg.com:9014/${id}`
    // Production Server
    // `https://prod.interactionserver.degpeg.com:9012/${id}`
    // );
    // const response = io.connect(`http://localhost:9010/${id}`);
    const response = io.connect(
      // Local Server
      // `http://localhost:9010/content-publisher`,
      // {
      //   query: {
      //     contentPublisherId: this.contentPublisherId
      //   },
      //   transports: ["polling"]
      // }
      //dev Server
      // `https://dev.interactionserver.degpeg.com:9014/content-publisher`,
      // {
      //   query: {
      //     contentPublisherId: this.contentPublisherId
      //   },
      //   transports: ["polling"]
      // }
      //prod Server
      `https://prod.interactionserver.degpeg.com:9012/content-publisher`,
      {
        query: {
          contentPublisherId: this.contentPublisherId
        },
        transports: ["polling"]
      }
    );
    return response;
  }
}

export default DegpegClient;